import React from 'react'
import Navbar from '../Components/Navbar'
import Hero from '../Components/Hero'
import HowitWorks from '../Components/HowitWorks'
import Others from '../Components/Others'
import Reviews from '../Components/Reviews'
import Contact from '../Components/Contact'
import Footer from '../Components/Footer'
// import Faqs from '../Components/Faqs'

const LandingPage = () => {
  return (
    <div><Navbar/>
    <Hero/>
    <HowitWorks/>
    <Others/>
    <Reviews/>
    {/* <Faqs/> */}
    <Contact/>
  <Footer/>
    </div>
  )
}

export default LandingPage