import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "../context";

const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const { login } = useUserContext();
  const handleLogin = async (values) => {
    await login(values);
  };
  return (
    <>
      <div className="login_flex">
        <div className="flex__1">
          <Link to="/">
            <img src="/images/t-logo.svg" alt="" />
          </Link>
        </div>
        <div className="flex__2">
          <div className="content_login">
            <img src="/images/weblogo.svg" alt="" />
            <h2 style={{ fontFamily: "kanit-bold" }} className="login_title">
              Login
            </h2>
            <input
              className="field mt-4 mb-4"
              type="email"
              placeholder="Email Address"
              onChange={(e) => setData({ ...data, email: e.target.value })}
            ></input>
            <input
              className="field mt-4 mb-4"
              type="password"
              placeholder="Password"
              onChange={(e) => setData({ ...data, password: e.target.value })}
            ></input>
            <div className="btn_start mb-3 login_fix" to="/login">
              {/* <Link to="/slot"> */}
              <button onClick={() => handleLogin(data)} className="login_btn">
                Login
              </button>
              {/* </Link> */}
            </div>
            <div style={{ fontFamily: "montserrat-regular" }}>
              Don’t have an account?
              <Link to="/signup">
                <span className="span pl-2">Register</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
