import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Radio, Space } from "antd";
const PatientsData = () => {
  const [updatedData, setUpdatedData] = useState({});
  const [data, setData] = useState({
    existingPatient: "",
    haveRecordId: "",
    patientId: "",
    DOB: "",
  });
  const navigation = useNavigate();
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    const storedData = localStorage.getItem("appointment-data");
    if (storedData) {
      setUpdatedData(JSON.parse(storedData));
    }
  };

  const handleUpdateData = (values) => {
    // Update data with additional fields
    const newData = { ...updatedData, ...values };

    // Store updated data in localStorage
    localStorage.setItem("appointment-data", JSON.stringify(newData));

    // Optionally, you can update state to reflect the changes
    setUpdatedData(newData);
    navigation("/PatientInfo");
  };
  return (
    <>
      <div className="header_flex">
        <div className="d-flex items_cnt">
          <img src="/images/arrow-left-light 1.svg" alt="" />
          <Link to="/slot">
            <div className="backbtn">Back</div>
          </Link>
        </div>
        <Link to="/">
          <img src="/images/weblogo.svg" alt="" />
        </Link>
        <div></div>
      </div>
      <div className="slot">
        <h2 className="">Patient details</h2>
      </div>
      <div className="solt_flex">
        <div>
          <div className="calender">
            <div className="mb-4" style={{ fontFamily: "kanit-bold" }}>
              Are you an existing patient ?
            </div>
            <Radio.Group
              onChange={(e) => {
                setData({ ...data, existingPatient: e.target.value });
              }}
              name="existingPatient"
            >
              <Space direction="vertical">
                <Radio value="yes">YES</Radio>
                <Radio value="no">NO</Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className="calender mb-4">
            <div className="mb-4 mt-4" style={{ fontFamily: "kanit-bold" }}>
              Do you know your Record Id?
            </div>
            <Radio.Group
              name="haveRecordId"
              onChange={(e) => {
                setData({ ...data, haveRecordId: e.target.value });
              }}
            >
              <Space direction="vertical">
                <Radio value="yes">YES</Radio>
                <Radio value="no">NO</Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>

        <div className="bottom_line"></div>
        <div className="slect_appoint">
          <div className="mb-3" style={{ fontFamily: "kanit-bold" }}>
            Available time slots
          </div>
          <input
            className="field mt-4 mb-4"
            type="text"
            placeholder="Patient ID"
            name="patientId"
            onChange={(e) => setData({ ...data, patientId: e.target.value })}
          ></input>
          <input
            className="field mt-4 mb-4"
            type="date"
            placeholder="Date of birth (mm/dd/yyyy)"
            onChange={(e) => setData({ ...data, DOB: e.target.value })}
            name="DOB"
          ></input>
        </div>
      </div>
      {/* <Link to="/PatientInfo"> */}
      <div className="btn_start text-center">
        <button onClick={() => handleUpdateData(data)} className="login_btn">
          Next
        </button>
      </div>
      {/* </Link> */}
    </>
  );
};

export default PatientsData;
