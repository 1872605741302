import React from "react";
import { Link } from "react-router-dom";

const SuccessAppoint = () => {
  return (
    <div>
      <div className="text-center mt-4">
        <Link style={{ textAlign: "text-center" }} to="/">
          <img src="/images/weblogo.svg" alt="" />
        </Link>
        <div></div>
      </div>
      <div className="slot mt-4">
        <h2 className="">Appointment booked !</h2>
        <div className="text-center mt-4">
          <img className="" src="/images/iii.svg" alt="" />
        </div>
        {/* <div className='success_title'>Thursday, February 23, 2023, 3:00 AM - 4:00 AM</div> */}
        <div className="success_title2">
          color: Your appointment has been successfully booked. You will be
          notified through email once the appointment is confirmed.;
        </div>
      </div>
    </div>
  );
};

export default SuccessAppoint;
