import React, { useState } from "react";
import { theme } from "antd";
import { Link, useNavigate } from "react-router-dom";

const WeightLossSlot = () => {
  const [data, setData] = useState({
    slot: {},
  });
  const navigation = useNavigate();
  const { token } = theme.useToken();
  const wrapperStyle = {
    width: 300,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };
  const handleNext = () => {
    localStorage.setItem("weight-loss-data", JSON.stringify(data));
    navigation("/weight-loss/data");
  };
  const separateDateTime = (inputStr) => {
    const inputDate = new Date(inputStr);

    // Format the date as DD-MM-YYYY
    const date = `${inputDate.getDate()}/${
      inputDate.getMonth() + 1
    }/${inputDate.getFullYear()}`;

    // Format the time in 12-hour format with AM/PM
    const hours = inputDate.getHours() % 12 || 12;
    const ampm = inputDate.getHours() >= 12 ? "PM" : "AM";
    const time = `${hours}:${String(inputDate.getMinutes()).padStart(
      2,
      "0"
    )} ${ampm}`;

    return { date, time };
  };
  return (
    <>
      <div className="header_flex">
        <div className="d-flex items_cnt">
          <img src="/images/arrow-left-light 1.svg" alt="" />
          <Link to="/">
            <div className="backbtn">Back</div>
          </Link>
        </div>
        <Link to="/">
          <img src="/images/weblogo.svg" alt="" />
        </Link>
        <div></div>
      </div>
      <div className="slot">
        <h2 className="">Appointment slot</h2>
      </div>
      <div className="solt_flex">
        <div className="calender">
          <div style={wrapperStyle}>
            <input
              type="datetime-local"
              onChange={(e) =>
                setData({ ...data, slot: separateDateTime(e.target.value) })
              }
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="bottom_line"></div>
        <div className="slect_appoint">
          <div className="mb-3" style={{ fontFamily: "kanit-bold" }}>
            Available time slots
          </div>
          <div>
            {data?.slot?.time ? (
              <button className="slot_number mr-3">{data.slot.time}</button>
            ) : null}
          </div>
        </div>
      </div>
      {/* <Link to="/data"> */}
      <div className="btn_start text-center" to="/login">
        <button onClick={handleNext} className="login_btn">
          Next
        </button>
      </div>
      {/* </Link> */}
    </>
  );
};

export default WeightLossSlot;
