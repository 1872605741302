import React from 'react'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className='hero_flex'>
        <div className='content_sec'>
            <div className='main_text' style={{background:"#2F80ED"}}>
            <div className='mbl_c' style={{color:"#fff",fontFamily:"montserrat-regular"}}>Telehealth Services for Dermatology,</div>
            <div className='mbl_c' style={{color:"#fff",fontFamily:"montserrat-regular"}}>Weight Management, STIs/STDs, & ED.</div>
            </div>
            <div className=''>
            <div className='text_div'><img src='/images/check-circle 1.svg' alt=''/><div className='pl-2 pt-1'>Simple and affordable. Your visit is $39.99.</div></div>
            <div className='text_div mt-3'><img src='/images/check-circle 1.svg' alt=''/><div className='pl-2 pt-1'>Your 24/7 online clinic.</div></div>
            <div className='text_div mt-3'><img src='/images/check-circle 1.svg' alt=''/><div className='pl-2 pt-1'>Board-certified medical practitioners.</div></div>
            <div className='text_div mt-3'><img src='/images/check-circle 1.svg' alt=''/><div className='pl-2 pt-1'>Start a visit instantly.</div></div>
            <div className='text_div mt-3'><img src='/images/check-circle 1.svg' alt=''/><div className='pl-2 pt-1'>Prescription to pharmacy of choice in 30min or less.</div></div>
            <div className='text_div mt-3'><img src='/images/check-circle 1.svg' alt=''/><div className='pl-2 pt-1'>Secure and confidential.</div></div>

        </div>
        <Link to="/login">   <div className="btn_start mt-4" to="/login">
                <button className="login_btn">Start Now</button>
              </div></Link>  
        </div>
      
        <div className='hero_img'>
            <img src='/images/heroimg.svg' alt=''/>
        </div>
    </div>
  )
}

export default Hero