import React, { useState } from "react";

const EditProfile = () => {
    const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
        <div className="main_nav">
        <div className="Navbar">
           <img className="" src="/images/weblogo.svg" alt="" />     
          <div className={`nav-items ${isOpen && "open"}`}>
            <div
              className="nav_items"
            >
              Home
            </div>
            <div
              className="nav_items"
            >
              About
              <img className="arrow" src="/images/dropimg.svg" alt="" />
            </div>
            <div
              className="nav_items"
            >
             How it works
            </div>
            <div
              className="nav_items "
            >
   Treatments & Services
            </div>
            <div
              className="nav_items "    
            >
       STD
            </div>
              <div className="btn_start mr-4" to="/login">
                <button className="login_btn">Start Now</button>
              </div>
              <img className="pro_img" src="/images/Ellipse 8.svg" alt=""/>          
          </div>
          <div
            className={`nav-toggle ${isOpen && "open"}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="bar"></div>
          </div>
        </div>
      </div>
      <div className="edit_section">
      <img className="pro_img fix_edit" src="/images/Ellipse 8.svg" alt=""/>
      <div className="update_btn">Update profile image</div>
      <input className='field field2 mt-4 mb-4' type='text' placeholder='Full Name'></input>
      <input className='field field2 mt-4 mb-4' type='text' placeholder='Email Address'></input>
      <input className='field field2 mt-4 mb-4' type='text' placeholder='Password'></input>
      <div className="btn_start mb-4 mt-4 " to="/login">
              <button className="login_btn">Update</button>
            </div> 
      </div>
    </div>
  )
}

export default EditProfile