import React, { useState } from "react";
import { toast } from "react-toastify";
import { contactUs } from "../Api";

const Contact = () => {
  const [data, setData] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const handleContact = async (value) => {
    if (value.firstName && value.email && value.phoneNumber && value.message) {
      try {
        const res = await contactUs(value);
        if (res?.status) {
          toast.success("Email send successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.error("All fileds required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <>
      <h2>Contact Us</h2>
      <div className="contact_us">
        <div className="img_section">
          <img src="/images/TaeAugust11 [Converted]-02 1.svg" alt="" />
        </div>
        <div className="contact_section">
          <input
            className="field mb-4"
            type="text"
            placeholder="First Name"
            name="firstName"
            onChange={(e) => setData({ ...data, firstName: e.target.value })}
          ></input>
          <input
            className="field mt-4 mb-4"
            type="email"
            placeholder="Email Address"
            name="email"
            onChange={(e) => setData({ ...data, email: e.target.value })}
          ></input>
          <input
            className="field mt-4 mb-4"
            type="text"
            placeholder="Phone Number"
            name="phoneNumber"
            onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
          ></input>
          <input
            className="field mt-4 mb-4"
            type="text"
            placeholder="Message"
            name="message"
            onChange={(e) => setData({ ...data, message: e.target.value })}
          ></input>
          <div className="btn_start mb-3">
            <button onClick={() => handleContact(data)} className="login_btn">
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
