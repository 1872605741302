import React from "react";

const Others = () => {
  return (
    <div className="section3">
      <h2 className="mt-4">YourteleRX vs Others</h2>
      <div style={{ color: "#828282" }} className="text-center pb-4 gpp">
        Dependable! Convenient! Swift! Economical! Life-changing! These are a
        few sentiments shared by our incredible community of over 450,000
        satisfied patients.
      </div>
      <div className="seccards">
        <div className="card4">
          <h2 className="heading_card">Other</h2>
          <div className="pb-3" style={{ color: "black", fontWeight: "700" }}>
            $60 - $250
          </div>
          <img src="/images/Group 7 (1).svg" alt="" />
          <div style={{ color: "#BDBDBD" }} className="pt-4">
            Waiting time
          </div>
          <div className="pb-4">Unknown</div>
          <div style={{ color: "#BDBDBD" }} className="pt-4 pb-1">
            Availability
          </div>
          <div>Unknown</div>
        </div>
        <div className="card4">
          <h2 className="heading_card">Urgent Care</h2>
          <div className="pb-3" style={{ color: "black", fontWeight: "700" }}>
            $100 -$300
          </div>
          <img src="/images/Group 7 (1).svg" alt="" />
          <div style={{ color: "#BDBDBD" }} className="pt-4">
            Waiting time
          </div>
          <div className="pb-4"> min 30</div>
          <div style={{ color: "#BDBDBD" }} className="pt-4">
            Availability
          </div>
          <div>8 - 12 hrs</div>
        </div>

        <div className="card4">
          <h2 className="heading_card">TeleRX</h2>
          <div className="pb-3" style={{ color: "black", fontWeight: "700" }}>
            $39.99
          </div>
          <img src="/images/Group 7 (1).svg" alt="" />
          <div style={{ color: "#BDBDBD" }} className="pt-4">
            Waiting time
          </div>
          <div className="pb-4"> mint 10</div>
          <div style={{ color: "#BDBDBD" }} className="pt-4">
            Availability
          </div>
          <div>24 hrs</div>
        </div>
      </div>
    </div>
  );
};

export default Others;
