import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signupUser } from "../Api";
import { toast } from "react-toastify";

const Signup = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const navigation = useNavigate();

  const handleSignUp = async (values) => {
    if (values.password && values.email && values.name) {
      try {
        const res = await signupUser(values);
        if (res?.status) {
          toast.success("Sign up successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigation("/login");
        }
      } catch (error) {
        toast.error(`${error?.response?.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigation("/register");
      }
    } else {
      toast.error("All fileds required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <>
      <div className="login_flex">
        <div className="flex__1">
          <Link to="/">
            <img src="/images/t-logo.svg" alt="logo" />
          </Link>
        </div>
        <div className="flex__2">
          <div className="content_login">
            <img src="/images/weblogo.svg" alt="" />
            <h2 style={{ fontFamily: "kanit-bold" }} className="login_title">
              Register
            </h2>
            <input
              className="field mt-4 mb-4"
              type="text"
              placeholder="Full Name"
              name="name"
              onChange={(e) => setData({ ...data, name: e.target.value })}
            ></input>
            <input
              className="field mt-4 mb-4"
              type="email"
              placeholder="Email Address"
              name="email"
              onChange={(e) => setData({ ...data, email: e.target.value })}
            ></input>
            <input
              className="field mt-4 mb-4"
              type="password"
              placeholder="Password"
              name="password"
              onChange={(e) => setData({ ...data, password: e.target.value })}
            ></input>
            <div className="btn_start mb-3 signup_fix" to="/login">
              <button onClick={() => handleSignUp(data)} className="login_btn">
                Sign up
              </button>
            </div>
            <div style={{ fontFamily: "montserrat-regular" }}>
              Already have an Account ?
              <Link to="/login">
                <span className="span">Login</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
