import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { fixAppointment } from "../Api";
import { toast } from "react-toastify";
const ConfirmAppoint = () => {
  const [updatedData, setUpdatedData] = useState({});
  const [data, setData] = useState({
    reason: "",
  });
  const [user, setUser] = useState();
  const navigation = useNavigate();
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    const storedData = localStorage.getItem("appointment-data");
    const userdata = localStorage.getItem("user");

    if (storedData && userdata) {
      setUpdatedData(JSON.parse(storedData));
      setUser(JSON.parse(userdata));
    }
  };
  const handleUpdateData = async (values) => {
    // Update data with additional fields
    const newData = { ...updatedData, ...values };

    // Store updated data in localStorage
    localStorage.setItem("appointment-data", JSON.stringify(newData));

    // Optionally, you can update state to reflect the changes
    try {
      if (newData) {
        const res = await fixAppointment(newData, user?.userId);
        if (res?.status) {
          navigation("/success-appoint");
        }
      } else {
        toast.error("All fileds required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error("Something wents wrong while making appointment!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigation("/login");
    }
  };
  return (
    <>
      <div className="d-flex pt-4">
        <div style={{ paddingRight: "460px" }} className="d-flex items_cnt">
          <img src="/images/arrow-left-light 1.svg" alt="" />
          <Link to="/full-data">
            <div className="backbtn">Back</div>
          </Link>
        </div>
        <div></div>
        <Link to="/">
          <img src="/images/weblogo.svg" alt="" />
        </Link>
        <div style={{ position: "absolute", right: "0" }} className="slot_div">
          <h2 className="" style={{ fontSize: "14px" }}>
            Review info
          </h2>
          <div className="d-flex pt-4">
            <div>
              <h2
                className="pr-4"
                style={{ fontSize: "14px", textAlign: "start" }}
              >
                Appointment slot
              </h2>
              <div style={{ fontSize: "9px" }}>
                {updatedData?.slot?.date}-{updatedData?.slot?.time}
              </div>
            </div>
            {/* <h2 style={{ fontSize: "14px", color: "#2F80ED" }}>Change</h2> */}
          </div>
          <div className="d-flex pt-4">
            <div>
              <h2
                className="pr-4 pl-0"
                style={{ fontSize: "14px", textAlign: "start" }}
              >
                Patient details
              </h2>
              <div
                className="pb-2"
                style={{ fontSize: "10px", textAlign: "start" }}
              >
                Are you an existing patient?
                <h2
                  style={{
                    fontSize: "9px",
                    color: "#828282",
                    textAlign: "start",
                  }}
                >
                  {updatedData?.existingPatient}
                </h2>
              </div>
              <div style={{ fontSize: "10px", textAlign: "start" }}>
                Do you know your Record Id?
                <h2
                  className="pt-2"
                  style={{
                    fontSize: "9px",
                    color: "#828282",
                    textAlign: "start",
                  }}
                >
                  {updatedData?.haveRecordId}
                </h2>
                <h2 style={{ fontSize: "10px", textAlign: "start" }}>
                  Patient record
                </h2>
                <div
                  className="pb-2"
                  style={{ fontSize: "9px", textAlign: "start" }}
                >
                  {updatedData.patientId}
                </div>
                <h2 style={{ fontSize: "9px", textAlign: "start" }}>
                  Date of birth
                </h2>
                <div style={{ fontSize: "9px", textAlign: "start" }}>
                  {updatedData?.DOB}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slot">
        <h2 className="">Confirm appointment</h2>
      </div>
      <div className="textarea">
        <textarea
          style={{ background: "#F2F2F2", height: "250px" }}
          className="field mt-4 mb-4 pt-2 pl-2"
          type="text"
          placeholder="Reason for appointment"
          name="reason"
          onChange={(e) => setData({ ...data, reason: e.target.value })}
        ></textarea>
      </div>
      <div className="text-center">
        <img src="/images/img66.svg" alt="" />
      </div>
      <div className="text-center h_h">
        <input
          className="field mt-4 mb-4"
          type="text"
          placeholder="Enter the text you see in the image above"
          name="reason"
        ></input>
      </div>
      {/* <Link to="/success-appoint"> */}
      <div className="btn_start text-center" to="/login">
        <button onClick={() => handleUpdateData(data)} className="login_btn">
          Next
        </button>
      </div>
      {/* </Link> */}
    </>
  );
};

export default ConfirmAppoint;
