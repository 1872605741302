import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="logo">
        <img src="/images/t-logo.svg" alt="" />
      </div>
      <div className="footer_content pb-4">
        <div className="content1">
          <div className="h1">Home</div>
          <div className="h5">About us</div>
          <div className="h5">How Its Works</div>
          <div className="h5">Treatment & Services</div>
          <div className="h5">STD</div>
        </div>
        <div className="content1">
          <div className="h1">Social</div>
          <div className="h5">Instagram</div>
          <div className="h5">LinkedIn</div>
          <div className="h5">Facebook</div>
          <div className="h5">STD</div>
        </div>
        <div className="content1">
          <div className="h1">Legal</div>
          <div className="h5">Terms</div>
          <div className="h5">Privacy</div>
          <div className="h5">Licenses</div>
          <div className="h5">Contact</div>
        </div>
        <div className="content1">
          <div className="h1">Social Links</div>
          <div>
            <a href="https://www.facebook.com">
              <img
                className="mr-3"
                src="/images/facebook-f-brands 1.svg"
                alt=""
              />
            </a>
            <a href="https://www.instagram.com">
              <img
                className="mr-3"
                src="/images/instagram-square-brands 2.svg"
                alt=""
              />
            </a>
            <a href="https://www.linkedin.com/feed">
              <img src="/images/linkedin-in-brands 1.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="border_line"></div>
      <div
        style={{ fontFamily: "montserrat-regular", color: "#fff" }}
        className="text-center pt-4 pb-4"
      >
        © 2023 YourTelerRX All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
