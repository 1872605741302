import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const HowitWorks = () => {
  const [user, setUser] = useState();
  useEffect(() => getData(), []);
  const getData = () => {
    setUser(JSON.parse(localStorage.getItem("user")));
  };
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (user) {
      navigate("/weight-loss/slot");
    } else {
      navigate("/login");
    }
  };
  const handleAppointNavigate = () => {
    if (user) {
      navigate("/slot");
    } else {
      navigate("/login");
    }
  };
  return (
    <div className="section2">
      <h2 className="mt-4">How it works</h2>
      <div className="cards">
        <div className="card">
          <img src="/images/3805193-02 1.svg" alt="" />
          <div className="text_1">Answer questions about your health</div>
          <div className="text_2">
            Provide Patient Details, Medical History, and Symptoms' Information.
            Typically Takes Five to Ten Minutes.
          </div>
        </div>
        <div className="card">
          <img src="/images/yusupjune20 [Converted]-02 1.svg" alt="" />
          <div className="text_1">Our providers will review your visit</div>
          <div className="text_2">
            Our certified medical professionals will craft a personalized
            treatment strategy tailored to your health history and condition.
            Easily monitor your treatment progress through our consultation
            tracker on your patient dashboard post-visit submission.
          </div>
        </div>
        <div className="card">
          <img src="/images/5418307-02 1.svg" alt="" />
          <div className="text_1">Pick Rx Medication from your pharmacy</div>
          <div className="text_2">
            Retrieve your prescription at a preferred pharmacy within 1-2 hours.
            Once you've received your treatment plan, contact your chosen
            pharmacy to inquire about the Rx fulfillment time. Some medications
            may also provide home delivery options for added convenience.
          </div>
        </div>
      </div>
      <div className="part_2">
        <div className="s_div">
          <img src="/images/head-side-cough-light 1.svg" alt="" />
          <div className="tex_s">Cough</div>
        </div>
        <div className="s_div">
          <img src="/images/hand-dots-light 1.svg" alt="" />
          <div className="tex_s">Skin / Acne</div>
        </div>
        <div className="s_div">
          <img src="/images/person-light-2 1.svg" alt="" />
          <div className="tex_s">Men Health</div>
        </div>
        <div className="s_div">
          <img src="/images/Group 5.svg" alt="" />
          <div className="tex_s">STDs / STIs</div>
        </div>

        <div onClick={() => handleNavigate()} className="s_div">
          <img src="/images/weight-scale-light 1.svg" alt="" />
          <div className="tex_s">Weight Loss</div>
        </div>
        <div onClick={() => handleAppointNavigate()} className="s_div blu">
          <div>Book</div>
          <div>APPOINTMENT</div>
        </div>
      </div>
    </div>
  );
};

export default HowitWorks;
