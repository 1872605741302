import React from "react";

const Reviews = () => {
  return (
    <div className="sections4">
      <h2>Reviews</h2>
      <div className="review_flex">
        <div className="review">
          <img
            style={{ height: "70px" }}
            className="pb-3"
            src="/images/Ellipse 2 (1).svg"
            alt=""
          />
          <h2 className="">Efficient</h2>
          <div className="pb-4 text_6">
            Efficient, convenient, and affordable! The telehealth service
            provided a seamless experience. The platform was user-friendly, and
            the medical professionals were knowledgeable. Highly recommended for
            anyone seeking accessible healthcare.
          </div>
          <img src="/images/Group 7 (1).svg" alt="" />
        </div>
        <div className="review">
          <img
            style={{ height: "70px" }}
            className="pb-3"
            src="/images/Ellipse 1.svg"
            alt=""
          />
          <h2 className="">Life-changing</h2>
          <div className="pb-4 text_6">
            Life-changing service! I was amazed by the ease of use and the
            quality of care received. The convenience of consultations from home
            was a game-changer. The medical team was attentive, providing a
            thorough understanding of my health concerns.
          </div>
          <img src="/images/Group 7 (1).svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Reviews;
