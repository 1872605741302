import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import LandingPage from "./Pages/LandingPage";
import Signup from "./Pages/Signup";
// import Appointmentpage from './Appointmentpage';
import EditProfile from "./Pages/EditProfile";
import AppointSlot from "./Pages/AppointSlot";
import PatientsData from "./Pages/PatientsData";
import PatientInfo from "./Pages/PatientInfo";
import FullData from "./Pages/FullData";
import ConfirmAppoint from "./Pages/ConfirmAppoint";
import SuccessAppoint from "./Pages/SuccessAppoint";
import { UserContextProvider } from "./context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WeightLossSlot from "./Pages/weightLoss/WeightLossSlot";
import PatientData from "./Pages/weightLoss/PatientData";
import PatientsInfo from "./Pages/weightLoss/PatientsInfo";
import FullDeatils from "./Pages/weightLoss/FullDeatils";
import Confirm from "./Pages/weightLoss/Confirm";

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/slot" element={<AppointSlot />} />
        <Route path="/data" element={<PatientsData />} />
        <Route path="/PatientInfo" element={<PatientInfo />} />
        <Route path="/full-data" element={<FullData />} />
        <Route path="/confrim-appoint" element={<ConfirmAppoint />} />
        <Route path="/success-appoint" element={<SuccessAppoint />} />
        <Route path="/weight-loss/slot" element={<WeightLossSlot />} />
        <Route path="/weight-loss/data" element={<PatientData />} />
        <Route path="/weight-loss/PatientInfo" element={<PatientsInfo />} />
        <Route path="/weight-loss/full-data" element={<FullDeatils />} />
        <Route path="/weight-loss/confrim-appoint" element={<Confirm />} />
      </Routes>
      <ToastContainer />
    </UserContextProvider>
  );
}
export default App;
