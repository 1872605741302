import React, { useState } from "react";
import "../Navbar.css";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
const Navbar = ({ moveToDiv }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="main_nav">
        <div className="Navbar">
           <img className="" src="/images/weblogo.svg" alt="" />     
          <div className={`nav-items ${isOpen && "open"}`}>
            <div
              className="nav_items"
            >
              Home
            </div>
            <div
              className="nav_items"
            >
              About
              <img className="arrow" src="/images/dropimg.svg" alt="" />
            </div>
            <div
              className="nav_items"
            >
             How it works
            </div>
            <div
              className="nav_items "
            >
   Treatments & Services
            </div>
            <div
              className="nav_items "    
            >
       STD
            </div>
            <Link to="/login">
            <div className="btn_start">
                <button className="login_btn">Start Now</button>
              </div> </Link>         
          </div>
          <div
            className={`nav-toggle ${isOpen && "open"}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="bar"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
